<template>
    <vs-popup class="holamundo"  title="Approve application " :active.sync="showModel">
      <p>
You are approving the merchant application. Once this application is approved you will no longer be able to edit the form or perform any compliance actions.
      </p>

        <div class="flex mt-3 ">
            <div class="w-full">
                <label> Please ensure you have done the following : </label>
              <ul class="mt-3 approve-application">
                    <li> Uploaded the Equifax beneficial ownership report</li>
                     <li> Uploaded the Equifax credit  report</li>
                     <li> Send and confirm completion of ID verification requests for all beneficial owners and authorized signatures.</li>
                </ul>
            </div>
        </div>




      <div class="flex flex-right">
             <vs-button
            flat
            v-round
            @click="Approve()"
            class="border-only-btn mr-2">Approve </vs-button>

              <vs-button
            flat
            v-round
            @click="closeModel()"
            class="border-only-btn mr-2">Cancel </vs-button>

      </div>



    </vs-popup>


</template>
<script>
import {mapActions} from 'vuex'
export default {

    props:['showModel','onboardingRequestId','banner'],
    methods:{
        ...mapActions("onboardingAdmin",['statusUpdate']),
        closeModel(){
            this.showModel = false;
        },
        async Approve(){
            const data = {
                status:"Approved",
                onboardingRequestId:this.onboardingRequestId,
                rejectedNote:""
            }
            await this.statusUpdate(data).then((result)=>{
                 this.showModel = false;
                 window.scrollTo(0, 0);
                 this.banner = {
                     color:"success",
                     message:"Application Approved",
                     show:true
                 }
            }).catch((err)=>{
                
                 //this.showModel = false;
            })
        }
    },
}
</script>

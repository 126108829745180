<template>
  <div class="onboarding-form idverificationForm">
    <div v-for="(detail, key) in verifiedIdList" :key="key">
      <div class="flex items-center">
        <div class="w-full id-verify-dates">
          <div class="flex items-center mb-6">
            <span v-if="detail.idMatrix === true" class="mb-0 mr-2 flex justify-center items-center">
              <vs-icon icon="check_circle_outline" size="small" color="#219653"></vs-icon>
            </span>
            <span v-if="detail.idMatrix === false" class="mb-0 mr-2 flex justify-center items-center">
              <vs-icon icon="highlight_off" size="small" color="red"></vs-icon>
            </span>
            <label class="vs-input--label p-0 w-full m-0 text-xl block mb-0">{{ detail.fullName }} ({{detail.type}})</label>
          </div>
          <p class="">
            {{ detail.mobileNumber }} /
            <a :href="'mailto:' + detail.email || detail.emailAddress">{{ detail.email || detail.emailAddress }}</a>
          </p>
          <span class="mt-6" v-if="detail.actionLogs && detail.actionLogs.length > 0">
            <small class="mr-16" v-for="(action, key) in detail.actionLogs" :key="key">
              {{ action.type }} : {{ moment(action.date) }}
            </small>
          </span>
        </div>
        <div v-if="!detail.hasOwnProperty('idMatrix') && applicationStatus!=='Approved' || detail.idMatrix === false">
          <vs-button v-if="detail.actionLogs.length > 0" @click="sendVerification(key, 'Re-sent')" v-round class="only-border-btn" size="medium"> Resend request </vs-button>
          <vs-button v-else color="" @click="sendVerification(key, 'Requested')" v-round size="medium"> Send Request </vs-button>
        </div>
      </div>
      <hr class="line-hr my-8" v-if="verifiedIdList[1]" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
export default {
  props: ["IdDetail", "applicationStatus"],
  data() {
    return {
      uniqueId: "",
      onboardingRequestId: "",
      mergedIdvLists: [],
    };
  },
  created() {
    this.onboardingRequestId = this.$route.params.onboardingId;
  },
  computed: {
    verifiedIdList() {
      let idLists = this.IdDetail;
      let totalCompanyList = [];
      idLists.forEach((boOwners) => {
        if (boOwners.entityType == "Company" ) {
          boOwners.beneficialOwners.forEach((boDetail) => {
            let newboDetail = JSON.parse(JSON.stringify(boDetail));
            newboDetail.type = "Beneficial owner";
            newboDetail.entityType = "Company";
            newboDetail.uniqueId = newboDetail._id; // for selecting respective beneficial owner
            newboDetail.ownershipId = boOwners._id; // for selecting ownership array
            totalCompanyList.push(newboDetail);
          });
        }

        if (boOwners.entityType == "Individual" ) {
          let newboOwners = JSON.parse(JSON.stringify(boOwners));
          newboOwners.type = "Individual";
          newboOwners.entityType = "Individual";
          newboOwners.actionLogs = newboOwners.individualIdMatrix.actionLogs;
          newboOwners.uniqueId = newboOwners._id;  // for selecting ownership array
          newboOwners.idMatrix = newboOwners.individualIdMatrix.idMatrix;
          totalCompanyList.push(JSON.parse(JSON.stringify(newboOwners)));
        }
      })

      this.mergedIdvLists = totalCompanyList;
      return totalCompanyList;
    },

    actionLogsComputed() {
      this.$lodashHelper.checkObjectKey(this.detail, "actionLogs", []);
    }
  },
  methods: {
    ...mapActions("onboardingAdmin", ["sendIdVerificationRequest"]),

    moment(date) {
      return moment(date).format("DD MMM YYYY");
    },

    async sendVerification(index, type) {
      let data = this.mergedIdvLists[index];
      data.action = type;
      data.onboardingRequestId = this.onboardingRequestId;
      await this.sendIdVerificationRequest(data).then((result) => {
        this.IdDetail = result.data.data;
        JSON.parse(JSON.stringify(this.IdDetail));
      }).catch((err) => {
        this.$vs.notify({
          title: err.response.data.title,
          text: err.response.data.message,
          color: "danger",
          iconPack: "feather",
          position: "top-right",
          icon: "",
          fixed: true,
        });
      });
    },
  },

};
</script>

<template>
  <vs-row class="mt-2 onboarding-form">
    <vs-col vs-xs="12">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-12 gap-y-6 md:gap-y-8">
        <div class="" v-for="(contact, index) in contactDetails" :key="index">
          <label class="vs-input--label pl-0 review-label w-full block text-base">{{contact.name}}</label>
          <p :class="getChanges(index, 'email') ? 'textBlack changed' : 'textBlack'" class="mb-1">{{contact.email}}</p>
          <p :class="getChanges(index, 'phoneNumber') ? 'textBlack changed' : 'textBlack'">{{contact.phoneNumber}}</p>
        </div>
      </div>
    </vs-col>
  </vs-row>
</template>

<script>
export default {
  name: "ContactDetails",
  props: ["contactDetails", "status", "resubmitChanges"],
  methods: {
    getChanges(index, flag){
      if(this.resubmitChanges.length > 0 && this.status == 'Resubmitted'){
        let changedlabel = `contactDetails[${index}]${flag}`
        let objData = this.resubmitChanges.findIndex(
          (obj) => obj.label === changedlabel
        );
        if(objData >= 0){
          return true;
        }
      }
      return false;
    }
  }
};
</script>

<template>
  <div>
    <hr class="line-hr mb-8 md:mb-10 w-full ml-1">

    <div class="onboarding-form">
      <div class="flex flex-wrap mt-4" v-if="message">
        <p>{{ message }}</p>
      </div>
      <!-------onboarding custom fields------>
      <div class="flex mt-4 flex-wrap" v-if="onboardingCustomFields && onboardingCustomFields.length > 0">
        <div :class="getChanges(cfindex) ? 'input-grp mt-8 changed' : 'input-grp mt-8'" v-for="(customField,cfindex) in onboardingCustomFields" :key="cfindex">
          <label class="vs-input--label pl-0 review-label w-full">{{ customField.label }}</label>
          <p class="textBlack" v-if="customField.dataType === 'currency'">$ {{ formatToCurrency(customField.value) }}</p>
          <p class="textBlack" v-else-if="customField.dataType === 'number'">{{ formatToCurrency(customField.value) }}</p>
          <p class="textBlack" v-else-if="customField.dataType === 'file'"><S3FileGenerator :document="customField.value"/></p>
          <p class="textBlack" v-else>{{ customField.value }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import S3FileGenerator from "../../../components/S3FileGenerator";

export default {
  name: "BusinessProfile",
  components: { S3FileGenerator },
  props:["businessProfile", "showEditButton", "userId", "onboardingCustomFields", "status", "resubmitChanges", "message"],
  data() {
    return {
      payload: {
        isComplete: false,
        product: "",
        productDescription: "",
        businessSummary: "",
        annualTurnoverLast12Month: 0.00,
        forecastTurnover: 0.00,
        profitLast12Month: 0.00,
        averageTransactionSize: 0,
        transactionsPerMonth: 0,
        averageDebtors: 0
      }
    }
  },
  methods:{
    formatToCurrency(amount){
      if (!amount) {
        return amount;
      }

      var formatter = new Intl.NumberFormat('en-US', {
        style: "currency",
        currency: "USD",

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(amount).replace("$","");
    },
    getChanges(index){
      if (this.resubmitChanges.length > 0 && this.status == "Resubmitted") {
        let changedlabel = `onboardingCustomFields[${index}]${this.onboardingCustomFields[index].label}`;
        let objData = this.resubmitChanges.findIndex((obj) => obj.label === changedlabel);

        if (objData >= 0) {
          return true;
        }
      }

      return false;
    }
  },
}
</script>

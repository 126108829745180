<template>
  <vs-popup class="holamundo" title="Reject application" :active.sync="showModel">
    <p>You are rejecting this merchant's application. Please note you can't undo this action.</p>

    <div class="flex mt-3">
      <div class="w-full">
        <label> Reason for rejection * </label>
        <vs-textarea v-validate="'required'" placeholder="Please detail the reason this application is being rejected" v-model="rejectNote" />
        <span v-show="errors.has(`supportingdoc`)" class="text-danger text-sm">
          {{ changeMessage(errors.first("supportingdoc"), "supportingdoc", "title") }}
        </span>
      </div>
    </div>
    <div class="flex flex-right">
      <vs-button flat v-round @click="Reject()" class="border-only-btn mr-2">Reject</vs-button>
      <vs-button flat v-round @click="closeModel()" class="border-only-btn mr-2">Cancel</vs-button>
    </div>
  </vs-popup>
</template>

<script>
import {mapActions} from "vuex";

export default {
  props: ["showModel", "onboardingRequestId"],
  data() {
    return {
      rejectNote: ""
    }
  },
  methods: {
    ...mapActions("onboardingAdmin", ["statusUpdate"]),

    closeModel() {
      this.showModel = false;
    },

    async Reject() {
      const data = {
        status: "Rejected",
        onboardingRequestId: this.onboardingRequestId,
        rejectedNote: this.rejectNote
      };

      await this.statusUpdate(data).then((result) => {
        this.showModel = false;
        window.scrollTo(0, 0);
        this.banner = {
          color: "danger",
          message: "Application rejected",
          show: true
        }
      });
    },

    changeMessage(data, removeString, addString) {
      return data ? data.replace(removeString, addString) : "";
    },
  },
}
</script>



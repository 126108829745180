<template>
  <div>
    <div v-for="(entityDetail, countent) in entityList" :key="countent">
      <vs-row>
        <div  class="w-full mb-8">
          <div class="flex mb-2 items-center ml-1">
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 4V2C10 0.9 9.1 0 8 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H18C19.1 18 20 17.1 20 16V6C20 4.9 19.1 4 18 4H10ZM4 16H2V14H4V16ZM4 12H2V10H4V12ZM4 8H2V6H4V8ZM4 4H2V2H4V4ZM8 16H6V14H8V16ZM8 12H6V10H8V12ZM8 8H6V6H8V8ZM8 4H6V2H8V4ZM17 16H10V14H12V12H10V10H12V8H10V6H17C17.55 6 18 6.45 18 7V15C18 15.55 17.55 16 17 16ZM16 8H14V10H16V8ZM16 12H14V14H16V12Z" fill="#BDBDBD"/>
            </svg>

            <h4 class="lg:text-xl md:text-lg text-sm mb-0 ml-5">
              Legal entity {{countent+1}}
            </h4>
          </div>
          <hr class="line-hr mb-8" />
          <div class="onboarding-form">
            <div>
              <div class="flex items-center flex-wrap">
                <div :class="getChanges(countent, 'entityType') ? 'input-grp changed' : 'input-grp'">
                  <label class="vs-input--label p-0 w-full review-label">Entity type</label>
                  <p class="textBlack">{{ entityDetail.entityType }}</p>
                </div>
              </div>

              <div v-if="entityDetail.entityType === 'Company'">
                <div class="flex items-center flex-wrap ">
                  <div :class="getChanges(countent, 'companyName') ? 'input-grp mt-5 changed' : 'input-grp mt-5'">
                    <label class="vs-input--label p-0 w-full review-label">Company name</label>
                    <p class="textBlack">{{ entityDetail.companyName }}</p>
                  </div>
                </div>
              </div>

              <div>
                <div class="flex flex-wrap" v-if="entityDetail.entityType === 'Company'">
                  <div :class="getChanges(countent, 'acn') ? 'input-grp mt-5 changed' : 'input-grp mt-5'">
                    <label class="vs-input--label p-0 w-full review-label">ACN</label>
                    <p class="textBlack">{{ entityDetail.acn }}</p>
                  </div>

                  <div :class="getChanges(countent, 'abn') ? 'input-grp mt-5 changed' : 'input-grp mt-5'" v-if="entityDetail.abn">
                    <label class="vs-input--label p-0 w-full review-label">
                      ABN (if registered under this company)
                    </label>
                    <p class="textBlack"> {{ entityDetail.abn }}</p>
                  </div>
                </div>

                <div class="flex flex-wrap" v-if="entityDetail.entityType === 'Individual'">
                  <div :class="getChanges(countent, 'fullName') ? 'input-grp mt-5 changed' : 'input-grp mt-5'">
                    <label class="vs-input--label p-0 w-full review-label">Full name</label>
                    <p class="textBlack">{{ entityDetail.fullName }}</p>
                  </div>

                  <div :class="getChanges(countent, 'abn') ? 'input-grp mt-5 changed' : 'input-grp mt-5'" v-if="entityDetail.abn">
                    <label class="vs-input--label p-0 w-full review-label">
                      ABN (if registered under this individual)
                    </label>
                    <p class="textBlack">{{ entityDetail.abn }}</p>
                  </div>
                </div>

                <div class="flex flex-wrap">
                  <div :class="getChanges(countent, 'mobileNumber') ? 'input-grp mt-5 changed' : 'input-grp mt-5'" v-if="entityDetail.entityType === 'Individual'">
                    <label class="vs-input--label p-0 w-full review-label">Mobile phone number</label>
                    <p class="textBlack">{{ entityDetail.mobileNumber }}</p>
                  </div>

                  <div :class="getChanges(countent, 'emailAddress') ? 'input-grp mt-5 changed' : 'input-grp mt-5'" v-if="entityDetail.entityType === 'Individual'">
                  <label class="vs-input--label p-0 w-full review-label">Email address</label>
                  <p class="textBlack">{{ entityDetail.emailAddress }}</p>
                </div>
                </div>
              </div>
            </div>

            <div v-if="entityDetail.entityType">
              <div class="mt-10 mb-4">
                <div class="flex mt-6 flex-wrap">
                  <div :class="getChanges(countent, 'registeredAddress') ? 'input-grp changed' : 'input-grp'">
                    <label class="text-base font-normal w-full review-label">Registered Address</label>
                    <p class="textBlack">{{ getRegisteredAddress(entityDetail.registeredAddress) }}</p>
                  </div>

                  <div :class="getChanges(countent, 'tradingAddress') ? 'input-grp changed' : 'input-grp'">
                    <label class="text-base font-normal w-full review-label">Trading address</label>
                    <p class="textBlack">{{ getRegisteredAddress(entityDetail.tradingAddress) }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-10 flex flex-wrap" v-if=" entityDetail.entityType !== '' && 'boCustomFields' in entityDetail && Array.isArray(entityDetail.boCustomFields) && entityDetail.boCustomFields.length > 0">
              <div v-for="(boFields, boIndexNumber) in entityDetail.boCustomFields" :key="boIndexNumber":class="getChanges(countent, '', boIndexNumber, 'boCustomFields') ? 'input-grp changed' : 'input-grp'">
                <div class="mb-5" v-if="matchEntityWithBoAndCustomCf(countent, boIndexNumber)">
                  <label class="vs-input--label w-full review-label">
                    {{ boFields.label }}
                  </label>
                  <p class="textBlack" v-if="boFields.dataType === 'currency'">
                    $ {{ formatToCurrency(boFields.value) }}
                  </p>
                  <p class="textBlack" v-else-if="boFields.dataType === 'number'">
                    {{ formatToCurrency(boFields.value) }}
                  </p>
                  <p v-else-if="boFields.dataType === 'file'">
                    <S3FileGenerator :document="boFields.value"/>
                  </p>
                  <p class="textBlack" v-else>
                    {{ boFields.value }}
                  </p>
                </div>
              </div>
            </div>

            <div v-if="entityDetail.entityType !=''">
              <div :class="getChanges(countent, 'isEntityActAsTrust') ? 'mt-10 mb-4 changed' : 'mt-10 mb-4'">
                <label class="text-base font-normal w-full review-label">Is this entity acting on behalf of a Trust?</label>
                <div class="flex my-1">
                  <div class="mt-4 w-2/12 flex justify-between">
                    <div class="textBlack flex justify-between mb-4">
                      {{ entityDetail.isEntityActAsTrust === "yes" ? "Yes" : "No" }}
                    </div>
                  </div>
                </div>

                <div v-if="entityDetail.isEntityActAsTrust=='yes' || entityDetail.isEntityActAsTrust==true" class="flex items-center flex-wrap ">
                  <div :class="getChanges(countent, 'trustName') ? 'input-grp changed' : 'input-grp'">
                    <label class="vs-input--label p-0 w-full review-label">Trust name</label>
                    <p class="textBlack">{{ entityDetail.trustName }}</p>
                  </div>

                  <div :class="getChanges(countent, 'trustAbn') ? 'input-grp changed' : 'input-grp'" v-if="entityDetail.trustAbn">
                    <label class="vs-input--label p-0 w-full review-label">ABN (if registered under this Trust)</label>
                    <p class="textBlack">{{ entityDetail.trustAbn }}</p>
                  </div>

                  <div :class="getChanges(countent, 'trustDeedFiles') ? 'input-grp mt-5 mb-8 changed' : 'input-grp mt-5 mb-8'">
                    <label class="vs-input--label p-0 w-full review-label">Trust deed</label>
                    <S3FileGenerator
                      :document="typeof entityDetail.trustDeedFiles === 'string'
                        ? entityDetail.trustDeedFiles
                        : entityDetail.trustDeedFiles !== undefined
                          ? entityDetail.trustDeedFiles.name
                          : null
                      "
                    />
                  </div>
                </div>
              </div>
              <div>
                <div v-if="entityDetail.entityType === 'Company'">
                  <h3 class="text-base font-normal mt-8">
                    Beneficial owners
                  </h3>
                  <hr class="line-hr mt-4 mb-6" />

                  <div class="mb-8" v-for='(bodetail, boindex) in entityDetail.beneficialOwners' :key="boindex">
                    <div class="">
                      <svg
                        v-if="entityDetail.beneficialOwners.length>1"
                        class="ml-auto float-right"
                        @click="performDelete(bodetail, boindex, countent)"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.66675 4.99992C1.66675 4.53968 2.03984 4.16658 2.50008 4.16658H17.5001C17.9603 4.16658 18.3334 4.53968 18.3334 4.99992C18.3334 5.46016 17.9603 5.83325 17.5001 5.83325H2.50008C2.03984 5.83325 1.66675 5.46016 1.66675 4.99992Z"
                          fill="#757575"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.33342 2.49992C8.1124 2.49992 7.90044 2.58772 7.74416 2.744C7.58788 2.90028 7.50008 3.11224 7.50008 3.33325V4.16658H12.5001V3.33325C12.5001 3.11224 12.4123 2.90028 12.256 2.744C12.0997 2.58772 11.8878 2.49992 11.6667 2.49992H8.33342ZM14.1667 4.16658V3.33325C14.1667 2.67021 13.9034 2.03433 13.4345 1.56548C12.9657 1.09664 12.3298 0.833252 11.6667 0.833252H8.33342C7.67037 0.833252 7.03449 1.09664 6.56565 1.56548C6.09681 2.03433 5.83342 2.67021 5.83342 3.33325V4.16658H4.16675C3.70651 4.16658 3.33341 4.53968 3.33341 4.99992V16.6666C3.33341 17.3296 3.59681 17.9655 4.06565 18.4344C4.53449 18.9032 5.17037 19.1666 5.83342 19.1666H14.1667C14.8298 19.1666 15.4657 18.9032 15.9345 18.4344C16.4034 17.9655 16.6667 17.3296 16.6667 16.6666V4.99992C16.6667 4.53968 16.2937 4.16658 15.8334 4.16658H14.1667ZM5.00008 5.83325V16.6666C5.00008 16.8876 5.08788 17.0996 5.24416 17.2558C5.40044 17.4121 5.6124 17.4999 5.83342 17.4999H14.1667C14.3878 17.4999 14.5997 17.4121 14.756 17.2558C14.9123 17.0996 15.0001 16.8876 15.0001 16.6666V5.83325H5.00008Z"
                          fill="#757575"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M8.33342 8.33325C8.79365 8.33325 9.16675 8.70635 9.16675 9.16658V14.1666C9.16675 14.6268 8.79365 14.9999 8.33342 14.9999C7.87318 14.9999 7.50008 14.6268 7.50008 14.1666V9.16658C7.50008 8.70635 7.87318 8.33325 8.33342 8.33325Z"
                          fill="#757575"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.6667 8.33325C12.127 8.33325 12.5001 8.70635 12.5001 9.16658V14.1666C12.5001 14.6268 12.127 14.9999 11.6667 14.9999C11.2065 14.9999 10.8334 14.6268 10.8334 14.1666V9.16658C10.8334 8.70635 11.2065 8.33325 11.6667 8.33325Z"
                          fill="#757575"
                        />
                      </svg>

                      <h3 class="text-base font-normal">
                      Beneficial owner {{ parseInt(boindex) + 1 }}
                    </h3>
                    </div>

                    <div :class="getChanges(countent, 'fullName', boindex, 'beneficialOwners') ? 'input-grp mt-3 changed' : 'input-grp mt-3'">
                      <label class="vs-input--label p-0 w-full review-label">Full name</label>
                      <p class="textBlack">{{ bodetail.fullName }}</p>
                    </div>

                    <div  class="flex items-center flex-wrap mt-3">
                      <div :class="getChanges(countent, 'mobileNumber', boindex, 'beneficialOwners') ? 'input-grp changed' : 'input-grp'">
                        <label class="vs-input--label p-0 w-full review-label">Mobile phone number</label>
                        <p class="textBlack">{{ bodetail.mobileNumber }}</p>
                      </div>

                      <div :class="getChanges(countent, 'email', boindex, 'beneficialOwners') ? 'input-grp changed' : 'input-grp'">
                        <label class="vs-input--label p-0 w-full review-label">Email address</label>
                        {{ bodetail.email }}
                      </div>
                    </div>
                  </div>
                </div>

                <div v-if="entityDetail.entityType === 'Company'" class="w-full mt-8">
                  <a class="text-base mid-blue flex items-center" @click="showPopup(countent)">
                    <span class="text-base mid-blue underline">+ Add Beneficial Owner</span>
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </vs-row>
    </div>

    <!-- Add  -->
    <vs-popup
      class="beneficial-popup"
      title="Add beneficial owner"
      :active.sync="popupActive"
      @close="closePopup"
    >
      <p class="mb-8">
        Please provide the following details about this beneficial owner.
      </p>
      <div class="flex">
        <div class="w-full mt-2">
          <vs-row class="mb-4">
            <vs-col class="pr-6" vs-xs="12">
              <div class="flex mb-8">
                <div class="input-grp">
                  <label>Full Name</label>
                  <vs-input
                    v-model="form.fullName"
                    data-vv-as="full name"
                    data-vv-scope="addBO1"
                    v-validate="'required'"
                    name="Full Name"
                    data-vv-validate-on="blur"
                    class="w-full"
                    @keyup="checkIfCardIsComplete"
                  />
                  <span
                    v-show="errors.has(`addBO1.Full Name`)"
                    class="text-danger text-sm"
                  >
                    {{ errors.first(`addBO1.Full Name`) }}
                  </span>
                </div>
              </div>

              <div class="flex mb-8">
                <div class="input-grp">
                  <label>Mobile phone number</label>
                  <vs-input
                    name="Mobile"
                    data-vv-validate-on="blur"
                    data-vv-as="mobile"
                    data-vv-scope="addBO1"
                    v-model="form.mobileNumber"
                    v-mask="'#### ### ###'"
                    v-validate="{
                      required: true,
                      regex: /^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3}$/,
                    }"
                    class="w-full"
                    @keyup="checkIfCardIsComplete"
                  />
                  <span
                    v-show="errors.has(`addBO1.Mobile`)"
                    class="text-danger text-sm"
                  >
                    {{ errors.first(`addBO1.Mobile`) }}
                  </span>
                </div>

                <div class="input-grp">
                  <label>Email Address</label>
                  <vs-input
                    name="Email"
                    data-vv-validate-on="blur"
                    data-vv-as="email"
                    data-vv-scope="addBO1"
                    v-model="form.email"
                    v-validate="'required|email'"
                    class="w-full"
                    @keyup="checkIfCardIsComplete"
                  />
                  <span
                    v-show="errors.has(`addBO1.Email`)"
                    class="text-danger text-sm"
                  >
                    {{ errors.first(`addBO1.Email`) }}
                  </span>
                </div>
              </div>

              <div class="input-grp">
                <label>Audit note</label>
                <vs-input
                  name="AuditNote"
                  data-vv-validate-on="blur"
                  data-vv-as="audit note"
                  data-vv-scope="addBO1"
                  v-model="form.auditNote"
                  v-validate="'required'"
                  class="w-full"
                  @keyup="checkIfCardIsComplete"
                />
                <span
                  v-show="errors.has(`addBO1.AuditNote`)"
                  class="text-danger text-sm"
                >
                  {{ errors.first(`addBO1.AuditNote`) }}
                </span>
              </div>
            </vs-col>
          </vs-row>

          <vs-row class="justify-end items-center">
            <vs-button
              size="large"
              v-round
              class="mr-8"
              @click.prevent="addNewBeneficiaryOwner"
            >Add
            </vs-button>
            <a @click="closePopup" class="mid-blue"><u>Cancel</u></a>
          </vs-row>
        </div>
      </div>
    </vs-popup>

    <!-- Delete  -->
    <vs-popup
      id="delete-bo"
      class="delete-pop-up"
      title="Delete this beneficial owner?"
      :active.sync="deletePopupActive"
    >
      <p>
        Are you sure you want to permanently delete this Beneficial Owner? You
        can’t undo this action.
      </p>
      <vs-col class="my-4">
        <vs-col vs-w="6" class="w-full">
          <label>Audit note <span class="mid-blue">*</span></label>
          <vs-input
            name="DeleteAuditNote"
            v-model="deleteData.auditNote"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="audit note"
            data-vv-scope="deleteBeneficial"
            class="w-full"
          />
          <span
            v-show="errors.has(`deleteBeneficial.DeleteAuditNote`)"
            class="text-danger text-sm"
          >
            {{ errors.first(`deleteBeneficial.DeleteAuditNote`) }}
          </span>
        </vs-col>
      </vs-col>

      <vs-col vs-w="6" class="w-full">
        <vs-button
          flat
          v-round
          size="large"
          class="border-only-btn float-right ml-auto"
          @click.prevent="deleteEvent"
        >Delete
        </vs-button>
      </vs-col>
    </vs-popup>
  </div>
</template>

<script>
import S3FileGenerator from "../../../components/S3FileGenerator";
import { TheMask, mask } from "vue-the-mask";
import { EventBus } from "@/event-bus";
import { mapActions } from "vuex";
const tooltip = require("@/assets/images/bank.png");

export default {
  name: "BusinessEntity",
  components: {
    TheMask,
    S3FileGenerator
  },
  directives: { mask },
  props: {
    entityList: { type:Array },
    onboardingRequestId: { type: String },
    businessOwnershipCustomFields: { type: Array },
    status: {type: String},
    resubmitChanges: {type: Array},
  },
  data(){
    return{
      tooltip:tooltip,
      form: {
        entityIndex: undefined,
        fullName: "",
        mobileNumber: "",
        email: "",
        auditNote: ""
      },
      deleteData: {
        fullName: "",
        mobileNumber: "",
        email: "",
        auditNote: "",
        index: "",
        entityIndex: undefined,
      },
      popupActive: false,
      deletePopupActive: false
    }
  },
  methods:{
    ...mapActions("onboardingAdmin", [
      "addBeneficiaryOwner",
      "deleteBeneficiaryOwner",
    ]),

    showPopup(index) {
      this.$validator.reset();
      this.popupActive = true;
      this.form.entityIndex = index;
    },

    closePopup() {
      this.popupActive = false;
      this.form.fullName = "";
      this.form.mobileNumber = "";
      this.form.email = "";
      this.form.auditNote = "";
      this.$validator.reset();
    },

    checkIfCardIsComplete(slug="") {
      let isUnique = true
      let isMobileUnique = true
      let isEmailUnique = true
      let response = true
      for (let index = 0; index < this.entityList[this.form.entityIndex].beneficialOwners.length; index++) {
        let {fullName, mobileNumber, email} = this.entityList[this.form.entityIndex].beneficialOwners[index]

        if(this.form.fullName === fullName) {
          if(slug === "submit"){
            response = false
          }
          isUnique = false
          this.errors.add({
            field: `addBO1.Full Name`,
            msg: "This name has been already used."
          })
        }
        else if(isUnique){
          this.errors.remove(`addBO1.Full Name`)
        }
        //unique email
        if(this.form.email &&
          ( !this.errors.has(`addBo.Email`) ||
            this.errors.first(`addBo.Email`) == "This email has been already used."
          )
        ){
          if(`${email}` == `${this.form.email}`) {
            if(slug == "submit") {
              response = false
            }

            isEmailUnique = false
            this.errors.add({
              field: `addBO1.Email`,
              msg: "This email has been already used."
            })
          }
          else if(isEmailUnique){
            this.errors.remove(`addBO1.Email`)
          }
        }
        //unique mobile number
        if(this.form.mobileNumber &&
          ( !this.errors.has(`addBO1.Mobile`) ||
            this.errors.first(`addBO1.Mobile`) == "This number has been already used."
          )){

          if(`${mobileNumber}` ==
            `${this.form.mobileNumber}`){
            if(slug == "submit"){
              response = false
            }
            isMobileUnique = false
            this.errors.add({
              field: `addBO1.Mobile`,
              msg: "This number has been already used."
            })
          }
          else if(isMobileUnique){
            this.errors.remove(`addBO1.Mobile`)
          }
        }
      }
      return response;
    },

    addNewBeneficiaryOwner() {
      const isValid = this.checkIfCardIsComplete("submit")
      if(!isValid){
        return false
      }
      this.$validator
        .validate("addBO1.*")
        .then((response) => {
          if (response) {
            this.addBeneficiaryOwner({
              id: this.onboardingRequestId,
              data: this.form,
            })
              .then((response) => {
                EventBus.$emit(
                  "updateAudit",
                  response.data.data.auditLogs.pop()
                );
                this.entityList[this.form.entityIndex].beneficialOwners.push(
                  response.data.data.businessOwnership[this.form.entityIndex].beneficialOwners.pop()
                );
                this.closePopup();
                this.$vs.notify({
                  title: "New beneficial owner has been added.",
                  text: "Changes you made were saved successfully.",
                  iconPack: "feather",
                  icon: "icon-check-circle",
                  color: "success",
                });
                this.$emit("updateBusinessInfo");
              })
              .catch((error) => {
                console.log("error", error);
                this.popupActive = false;
              })
              .finally(() => {
                this.popupActive = false;
                this.form.fullName = "";
                this.form.entityIndex = "";
                this.form.mobileNumber = "";
                this.form.email = "";
                this.form.auditNote = "";
              });
          }
        })
        .catch((er) => {
          console.log("e", er);
        });
    },

    performDelete(beneficiary, key, entityIndex) {
      this.deleteData.fullName = beneficiary.fullName;
      this.deleteData.email = beneficiary.email;
      this.deleteData.mobileNumber = beneficiary.mobileNumber;
      this.deleteData.index = key;
      this.deleteData.entityIndex = entityIndex;
      this.deletePopupActive = true;
    },

    deleteEvent() {
      this.$validator.validate("deleteBeneficial.*").then((result) => {
        if (result) {
          this.deleteBeneficiaryOwner({
            id: this.onboardingRequestId,
            data: this.deleteData,
          })
            .then((response) => {
              EventBus.$emit("updateAudit", response.data.data.auditLogs.pop());
              this.entityList[this.deleteData.entityIndex].beneficialOwners.splice(this.deleteData.index, 1);
              this.deletePopupActive = false;
              this.$vs.notify({
                title: "Beneficial owner has been deleted.",
                text: "Changes you made were saved successfully.",
                iconPack: "feather",
                icon: "icon-check-circle",
                color: "success",
              });
              this.$emit("updateBusinessInfo");
            })
            .catch((error) => {
              console.log("error", error);
              this.deletePopupActive = false;
            })
            .finally(() => {
              this.deletePopupActive = false;
              this.deleteData.auditNote = "";
              this.deleteData.entityIndex = "";
              this.deleteData.index = "";
            });
        }
      });
    },

    formatToCurrency(amount) {
      if (!amount) return amount;
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return formatter.format(amount).replace("$", "");
    },

    matchEntityWithBoAndCustomCf(boIndex, cfIndex) {
      const boEntityType = this.entityList[boIndex].entityType;
      const cfEntityType = this.businessOwnershipCustomFields[cfIndex].entity;
      if (cfEntityType.includes(boEntityType.toLowerCase())) {
        return true;
      }
      return false;
    },

    getRegisteredAddress(data) {
      let addressData = [];
      if (data.appartmentDetail) {
        addressData.push(data.appartmentDetail);
      }
      if (data.streetAddress) {
        addressData.push(data.streetAddress);
      }
      if (data.suburb) {
        if (data.state) {
          addressData.push(data.suburb + " " + data.state);
        } else {
          addressData.push(data.suburb);
        }
      }
      if (data.country) {
        addressData.push(data.country);
      } else {
        addressData.push("Australia");
      }
      return addressData.join(", ");
    },

    getChanges(index, flag = null, customIndex = null, section = null){
      if(this.resubmitChanges.length > 0 && this.status == 'Resubmitted'){
        let changedlabel = `businessOwnership[${index}]${flag}`
        if(section && flag){
          changedlabel = `businessOwnership[${index}]${section}[${customIndex}]${flag}`
        }else if(section){
          changedlabel = `businessOwnership[${index}]${section}[${customIndex}]`
        }
        let objData = this.resubmitChanges.findIndex(
          (obj) => obj.label === changedlabel
        );
        if(objData >= 0){
          return true;
        }
      }
      return false;
    }
  }
}
</script>

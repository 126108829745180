<template>
  <div>
    <div class="onboarding-form">
      <div class="flex mt-6 flex-wrap">
        <div :class="getChanges('tradingName') ? 'input-grp changed' : 'input-grp'">
            <label class="vs-input--label w-full review-label">Trading name</label>
            <p class="textBlack mb-0">{{ payload.tradingName }}</p>
        </div>
      </div>
      <div class="flex mt-6 flex-wrap">
        <div :class="getChanges('industry') ? 'input-grp changed' : 'input-grp'">
          <label class="vs-input--label w-full review-label">Industry</label>
          <p class="textBlack mb-0">{{ payload.industry }}</p>
        </div>

        <div :class="getChanges('category') ? 'input-grp changed' : 'input-grp'">
          <label class="vs-input--label w-full review-label">Category</label>
          <p class="textBlack mb-0 ">{{ payload.category }}</p>
        </div>
      </div>
      <div class="flex mt-6 flex-wrap" v-if="payload.websiteUrl">
        <div :class="getChanges('websiteUrl') ? 'input-grp changed' : 'input-grp'" v-if="payload.websiteUrl">
          <label class="vs-input--label w-full review-label">Website URL</label>
          <p class="textBlack mb-0">{{ payload.websiteUrl }}</p>
        </div>
      </div>
      <div class="flex mt-6 flex-wrap">
        <div :class="getChanges('businessEmailAddress') ? 'input-grp changed' : 'input-grp'">
          <label class="vs-input--label w-full review-label">Business email address</label>
          <p class="textBlack mb-0">{{ payload.businessEmailAddress }}</p>
        </div>
        <div :class="getChanges('businessPhoneNumber') ? 'input-grp changed' : 'input-grp'">
          <label class="vs-input--label w-full review-label">Business phone number </label>
          <p class="textBlack mb-0">{{ payload.businessPhoneNumber }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import industries from "../resources/industries";
import categories from "../resources/categories";
export default {
  name: "GeneralInformation",
  props:{
    generalInfo:{type:Object},
    userId:String,
    showEditButton:Boolean,
    status:String,
    resubmitChanges:{type:Array},
  },
  data(){
    return{
      onboardingRequestId:this.$route.params.onboardingId,
      industries:industries,
      categories:categories,
      payload:{
        industry: "",
        category: "",
        websiteUrl: "",
        tradingName: "",
        businessEmailAddress: "",
        businessPhoneNumber: "",
      }
    }
  },
  methods:{
    goToEdit(data){

          this.generalEditModel = true;
          this.generalData = data;
        },
      async saveGeneralData(){

      },
      closeGeneralModel(value){

        this.generalEditModel = false

        this.$emit('reloadSaveData','Awesome ');
      },


  },
  mounted(){
    this.payload = this.generalInfo;
  },
  methods: {
    getChanges(e){
      if(this.resubmitChanges.length > 0 && this.status == 'Resubmitted'){
        let objData = this.resubmitChanges.findIndex(
          (obj) => obj.label === e
        );
        if(objData >= 0){
          return true;
        }
      }
      return false;
    }
  }

}
</script>
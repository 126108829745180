<template>
  <div>
    <vs-row>
      <div class="mt-10 mb-5" v-for="(user, key) in users" :key="key">
        <h3>User {{ key + 1 }}</h3>
        <hr class="line-hr" />

        <div class="flex items-start mt-6">
          <div class="flex items-center justify-center">
            <div class="input-grp">
              <label class="text-base p-0 w-full text-dark-blue">Full name <span class="mid-blue align-top">*</span></label>
              <vs-input v-model="user.fullName" data-vv-as="full name" data-vv-validate-on="blur" v-validate="'required'" :name="'fullName'+key" class="w-full surcharge-top" />
              <span v-show="errors.has('fullName'+key)"class="text-danger text-sm">{{ errors.first("fullName"+key) }}</span>
            </div>
            <div class="input-grp">
              <label class="text-base p-0 w-full text-dark-blue">Email address <span class="mid-blue align-top">*</span></label>
              <vs-input v-model="user.email" v-validate="'required|email'" :name="'email'+key" data-vv-as="email address" data-vv-validate-on="blur" class="w-full surcharge-top" />
              <span v-show="errors.has('email'+key)" class="text-danger text-sm">{{ errors.first("email"+key) }}</span>
            </div>
            <div>
              <label class="text-base p-0 w-full text-dark-blue">Role <span class="mid-blue align-top">*</span></label>
              <vs-select v-validate="'required'" :name="'role'+key" class="w-full rounded mt-2 mb-0" data-vv-as="role" v-model="user.role">
                <vs-select-item v-for="(role, index) in roles" :key="index + 0.1" :text="role" :value="role"></vs-select-item>
              </vs-select>
              <span v-show="errors.has('role'+key)" class="text-danger text-sm">{{ errors.first("role"+key) }}</span>
            </div>
            <div class="flex">
              <trash-icon size="1x" class="mt-8" @click="deleteUser(key)" v-if="users.length > 1"></trash-icon>
            </div>
          </div>
        </div>
      </div>
    </vs-row>
    <div class="mt-5">
      <span @click="addUser" class="text-sm flex item-center mt-1 cursor-pointer"><plus-icon size="1.5x" class="button-icon mr-3"></plus-icon> Add user</span>
    </div>
  </div>
</template>

<script>
import { PlusIcon, TrashIcon } from "vue-feather-icons";

export default {
  components: { PlusIcon, TrashIcon },
  props: {
    users: {
      type: Array
    },
    userValidations: {
      type: Array
    }
  },

  data() {
    return {
      defaultUser: {
        fullName: "",
        email: "",
        role: ""
      },
      roles: ["Admin", "User"],
      id: null,
    }
  },

  methods: {
    addUser() {
      this.users.push({...this.defaultUser});
      this.$forceUpdate();
    },

    async validate() {
      return await this.$validator.validateAll();
    },

    deleteUser(index) {
      this.users.splice(index, 1);
      this.$forceUpdate();
    }
  },
  created() {
    if (!this.users || !this.users.length) {
      this.users.push({...this.defaultUser});
      this.$forceUpdate();
    }
  },
  watch: {
    userValidations(val) {
      if (val && val.length) {
        val.forEach(item => {
          this.errors.add({
            field: item,
            msg: "Email already used",
          });
        });
      }
    }
  }
}
</script>


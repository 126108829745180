import accomodation from "./accomodation";
import administrative from "./administrative";
import agriculture from "./agriculture";
import arts from "./arts";
import construction from "./construction";
import education from "./education";
import electricity from "./electricity";
import finance from "./finance";
import healthcare from "./healthcare";
import informationmedia from "./informationmedia";
import manufacturing from "./manufacturing";
import minings from "./minings";
import otherservices from "./otherservices";
import professional from "./professional";
import publicadmin from "./publicadmin";
import rental from "./rental";
import rentaltrade from "./rentaltrade";
import transport from "./transport";
import wholesaletrade from "./wholesaletrade";


export default [
  {
    industry: "Book",
    "name": "Religious services"
  },
  ...accomodation,
  ...administrative,
  ...agriculture,
  ...arts,
  ...construction,
  ...education,
  ...electricity,
  ...finance,
  ...healthcare,
  ...informationmedia,
  ...manufacturing,
  ...minings,
  ...otherservices,
  ...professional,
  ...publicadmin,
  ...rental,
  ...rentaltrade,
  ...transport,
  ...wholesaletrade,
]

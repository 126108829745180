<template>
  <div class="onboarding-form">
    <div v-for="(doc, key) in payload" :key="key">
      <div class="flex">
        <div class="w-full">
          <label class="p-0 w-full text-grey text-base font-light">Note</label>
          <p class="text-base text-text-blue">{{ doc.note }}</p>
        </div>
        <div class="w-full" v-if="doc.filePath || doc.fileName">
          <label class="p-0 text-base text-grey font-light">Attachments</label>
          <S3FileGenerator :document="isValidPath(doc.fileName) ? doc.fileName : doc.filePath" />
        </div>
      </div>
      <hr class="line-hr mt-2" v-if="payload[1]"/>
      <div class="mb-8">&nbsp;</div>
    </div>

    <vs-button class="only-border-btn" @click="showPopup" size="medium" v-round v-if="applicationStatus !== 'Approved'">Add</vs-button>
    <vs-popup id="halomundo" class="holamundo sup-doc-popup" title="New note" :active.sync="popupActivo">
      <div class="flex mb-8">
        <div class="input-grp">
          <label class="vs-input--label p-0 text-base">Note <span class="mid-blue">*</span></label>
          <vs-textarea style="border-bottom: 1px solid #dedede !important;" v-model="note" name="Note" v-validate="'required'" class="mt-2 mb-8" data-vv-as="Note"></vs-textarea>
          <span v-show="errors.has(`Note`)" class="text-danger text-sm">{{ errors.first("Note") }}</span>
        </div>
        <div class="input-grp">
          <label class="vs-input--label p-0 w-full">Attachments</label>
          <input
            type="file"
            class="hidden"
            name="file"
            v-validate="{ size: maxFileSize * 1024 }"
            data-vv-as="file"
            ref="uploadImgInput"
            @change="updateCurrImg($event)"
            accept=".pdf"
          />
          <vs-button v-if="!file" class="only-border-btn mt-2" size="small" v-round @click="uploadImgInput()">
            Upload
          </vs-button>
          <span v-if="file" class="text-blue-light">{{ file.name }}</span>
          <span v-show="errors.has(`file`)" class="text-danger text-sm block">{{ errors.first("file") }}</span>
          <span v-if="!isPdfUpload" class="text-danger text-sm block">{{ "Please ensure file is a PDF document" }}</span>
        </div>
      </div>

      <div class="flex justify-end items-center">
        <vs-button size="large" v-round @click="saveFile()" class="border-only-btn mr-8">Add</vs-button>
        <a class="mid-blue underline" @click="closeModel()">Cancel</a>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Validator } from "vee-validate";
import S3FileGenerator from "../../../components/S3FileGenerator";

const dictionary = {
  en: {
    attributes: {},
  },
};
Validator.localize(dictionary);
export default {
  name: "SupportingDocument",
  components:{
    S3FileGenerator
  },
  props: {
    onboardingRequestId: { type: String },
    document: { type: Array },
    applicationStatus: { type: String },
  },
  data() {
    return {
      payload: [],
      popupActivo: false,
      file: "",
      note: "",
      isPdfUpload:true,
      maxFileSize: process.env.VUE_APP_FILE_MAX_SIZE_MB,
    };
  },
  methods: {
    ...mapActions("onboardingAdmin", ["saveSupportingFile"]),
    goToEdit() {
      this.$router.push({
        name: "application",
        params: { userId: this.userId, card: "businessProfile" },
      });
    },
    showPopup() {
      this.$validator.reset();
      this.popupActivo = true;
    },
    uploadImgInput() {
      this.$refs.uploadImgInput.click();
    },
    updateCurrImg(input, key) {
      let files = input.target.files[0];
      this.file = files;

      if (input.target.files[0]["type"] != "application/pdf") {
        this.errors.add({
          field: "file",
          msg: "Please ensure file is a PDF document"
        });
        this.isPdfUpload = false;
      } else {
        this.isPdfUpload = true;
      }
    },
    async saveFile() {
      const validationState = await this.$validator.validateAll();

      if (!validationState || !this.isPdfUpload) {
        return false;
      }

      const formData = new FormData();

      formData.append("note", this.note);
      formData.append("onboardingrequestId", this.onboardingRequestId);
      formData.append("supportFile", this.file);

      const payloadObj = {
        data: formData,
        config: {
          header: {
            "Content-Type": "multipart/form-data",
          },
        },
      };
      this.$vs.loading();
      this.saveSupportingFile(payloadObj).then((result) => {
        this.$vs.loading.close();
        this.note = "";
        this.file = "";
        this.closeModel();
        this.payload.push({ ...result.data.data.supportingDocuments.pop() });
        this.$emit("handleCardHeight", result.data.data.auditLogs);
        this.$emit("handleSupportingDocumentAdded", result.data.data.auditLogs);
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showMessage("Error", ex.data.message, "danger", "icon-times");
      });
    },
    changeMessage(data, removeString, addString) {
      return data ? data.replace(removeString, addString) : "";
    },
    closeModel() {
      this.popupActivo = false;
      this.file = "";
      this.note = "";
      this.$validator.reset();
      this.$refs.uploadImgInput.value = null;
      this.isPdfUpload = true;
      this.file = null;
    },
    async deleteFile() {
      this.file = null;
      this.$refs.uploadImgInput.value = null;
      await this.$validator.validateAll();
      this.$validator.reset();
      this.isPdfUpload = true;
    },
    baseUrl() {
      return process.env.VUE_APP_API_URL;
    },
    isValidPath(path) {
      if (!path) {
        return false;
      }
      const pathSplit = path.split("/");

      if (pathSplit.length === 1) {
        return false;
      }

      return ["files","images"].includes(pathSplit[0]);
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },
  },
  created() {
    this.payload = this.document;
  },
};
</script>


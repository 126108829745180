<template>
  <div class="ml-12">
    <div v-if="logs.length > 0" class="timeline">
      <template v-for="(log, index) in logs">
        <div :key="index" class=" timeline-items">
          <h5 class="font-normal text-base text-black pb-4">{{ log.title }}</h5>
          <div class="flex">
            <S3ImageGenerator
              v-if="auditedByProfileImage(log)"
              :document="auditedByProfileImage(log)"
              :key="auditedByProfileImage(log)"
              :is-base64="false"
              :custom-class="'auditLog-icon'"
            />
            <div class="ml-4 w-full">
              <p class="text-xs">{{ log.auditedBy.fullName }}</p>
              <h5 class="text-black">{{ `${dateFormat(log.auditedAt)} at ${timeFormat(log.auditedAt)} `}}</h5>
              <p class="font-medium mt-4 text-xs text-gray-font">NOTES</p>
                <hr class="line-hr"/>
              <div style="background:#f8f8f8;padding:10px 10px 16px 10px;border-radius:8px;">

                <h6 class="text-base font-normal">{{ log.auditNote }}</h6>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-else>
      There are no logs yet.
    </div>
  </div>
</template>

<script>
import {EventBus} from "../../../../event-bus";
import moment from "moment";
import S3ImageGenerator from "@/views/components/S3ImageGenerator";

export default {
  name: "AuditLog",
  components: {
    S3ImageGenerator
  },
  props: {
    logs: { type: Array, default: () => [] }
  },

  created() {
    EventBus.$on("updateAudit", log => {
      const tOut = setTimeout(() => {
        this.logs.push(log);
        clearTimeout(tOut);
      }, 100);
      this.$emit("handleCardHeight");
    });
  },

  methods: {
    dateFormat(date) {
      return moment(date).format("DD MMM YYYY");
    },
    timeFormat(date) {
      return moment(date).format("HH:mm");
    },
    auditedByProfileImage(log){
      return this.$lodashHelper.checkObjectKey(log, "auditedBy.profileImage", "")
    }
  },

  computed: {
    baseUrl() {
      return process.env.VUE_APP_API_URL;
    },
  }
}
</script>